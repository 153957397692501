.influencer-mobile-menu-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.influencer-mobile-menu {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 10px 0;
    margin: 0;
    list-style: none;
}

.influencer-mobile-menu .menu-item {
    flex: 1;
    text-align: center;
}

.influencer-mobile-menu .menu-link {
    text-decoration: none;
    color: #6c757d;
    font-size: 14px;
    display: block;
    width: 90%;
    margin: 0 auto;
}

.influencer-mobile-menu .menu-link.active {
    color: var(--bs-primary);
    font-weight: bold;
}

@media (min-width: 992px) {
    .influencer-mobile-menu-wrapper {
        display: none;
    }
}
