.operator-name {
    overflow: hidden;
    max-width: 65px;
    min-width: 55px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}
#testBTN:hover .test {
    display: inline-block;
}